import { requestGraphql } from '../api'

interface OptOutEmailsArgs {
  token: string
}

export function optOutEmails({ token }: OptOutEmailsArgs) {
  return requestGraphql({
    operationName: 'optOutEmails',
    variables: {
      input: {
        token,
      },
    },
    query: `
        mutation optOutEmails ($input: OptOutEmailsInput!) {
          optOutEmails (input: $input) { isSuccess }
        }
      `,
  })
}
