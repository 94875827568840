import { Link as NavLink } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Button } from '../../../components/forms'
import Page from '../../../components/layout/Page'
import { Spacer } from '../../../components/modules'
import { BodySemibold } from '../../../components/typography'
import CheckIcon from '../../../components/unsubscribe/check.inline.svg'
import { optOutEmails } from '../../../components/unsubscribe/utils'
import useQueryOrStorageParam from '../../../hooks/useQueryOrStorageParam'

enum SubmitState {
  NOT_SUBMITED,
  SUBMITING,
  SUBMITED,
  ERROR,
}

const Outer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
`

const Unsubscribe = () => {
  const token = useQueryOrStorageParam('token')

  const [submitState, setSubmitState] = useState(SubmitState.NOT_SUBMITED)
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    if (token && submitState === SubmitState.NOT_SUBMITED) {
      setSubmitState(SubmitState.SUBMITING)
      optOutEmails({ token })
        .then(() => [
          setSubmitState(SubmitState.SUBMITED),
          window.scrollTo(0, 0),
        ])
        .catch((error: Error) => {
          console.error('Error unsubscribing from emails', error)
          setErrorMessage(error.message)
          setSubmitState(SubmitState.ERROR)
        })
    }
  }, [token, submitState, setSubmitState])

  return (
    <Page
      head={{
        title: 'Unsubscribe',
        description: 'Unsubscribe from Double emails',
      }}
      nav={false}
    >
      <Outer>
        {[SubmitState.SUBMITED, SubmitState.SUBMITING].includes(
          submitState,
        ) && (
          <CheckIcon
            style={{
              visibility:
                submitState === SubmitState.SUBMITED ? 'visible' : 'hidden',
              marginBottom: 24,
            }}
          />
        )}

        <BodySemibold>
          {submitState === SubmitState.SUBMITING
            ? 'Your preferences are being updated...'
            : submitState === SubmitState.ERROR
            ? `An error occurred while updating your preferences, please contact us if the error persists. (${
                errorMessage || 'unknown error'
              })`
            : submitState === SubmitState.SUBMITED
            ? 'You have been unsubscribed from these emails.'
            : null}
        </BodySemibold>

        {submitState === SubmitState.SUBMITED && (
          <NavLink to={'/login'}>
            <Button title={'Open the app'} isValid style={{ marginTop: 32 }} />
          </NavLink>
        )}

        <Spacer size={'Medium'} />
      </Outer>
    </Page>
  )
}

Unsubscribe.route = '/unsubscribe'
Unsubscribe.title = 'Unsubscribe'

export default Unsubscribe
